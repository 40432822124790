<template>
  <v-container grid-list-md class="general">
    <PageTitle :title="'Settings'" />
    <v-layout row wrap>
      <v-flex xs12 mb-4>
        <v-card>
          <v-toolbar flat color="grey lighten-5">
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-list two-line subheader>
            <v-list-tile
              v-for="(setting, index) in settings"
              :key="`setting-${index}`"
              @click="showSetting(setting.id)"
            >
              <v-list-tile-content>
                <v-list-tile-title>{{ setting.key }}</v-list-tile-title>
                <v-list-tile-sub-title>
                  {{ setting.value }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-btn icon @click.stop="deleteSettings(setting.id)">
                  <font-awesome-icon class="important--text" icon="trash" />
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-flex>
      <v-flex xs12 text-xs-center v-if="totalPages > 1">
        <v-pagination
          v-model="page"
          :length="totalPages"
          :total-visible="totalVisible"
          @input="listSettings"
        ></v-pagination>
      </v-flex>
    </v-layout>
    <AppSpeedDial
      @click="$router.push({ name: 'settings_create' })"
      :hint="'Create settings'"
    />
  </v-container>
</template>

<script>
export default {
  data: () => ({
    settings: [],
    page: 1,
    totalPages: null,
    totalVisible: 5,
    perPage: 15,
  }),
  mounted() {
    if (this.$route.query.page) {
      this.page = Number(this.$route.query.page);
    }

    this.listSettings();
  },
  methods: {
    showSetting(id) {
      this.$router
        .push({
          name: "settings_edit",
          params: {
            setting_id: id,
          },
        })
        .catch(() => {});
    },
    listSettings() {
      this.$router
        .replace({
          query: {
            page: this.page,
          },
        })
        .catch(() => {});

      const params = {
        page: this.page,
        per_page: this.perPage,
      };

      this.$api.settings
        .list(params)
        .then((response) => {
          this.settings = response.data.data;
          this.totalPages = response.data.pagination.total_pages;
        })
        .catch(() => {});
    },
    async deleteSettings(id) {
      if (!id) return;

      const isConfirmed = await this.$vuetifyConfirmDialog.open(
        this.$tc("confirmation", 1),
        this.$t("setting-deletion-warning"),
        this.$t("cancel"),
        this.$t("proceed"),
      );

      if (!isConfirmed) return;

      this.$api.settings
        .delete(id)
        .then((response) => {
          this.$store.dispatch("addNotification", {
            message: this.$t("setting-deleted"),
          });

          this.page = 1;
          this.listSettings();
        })
        .catch((error) => this.errorMessageShow(error));
    },
  },
};
</script>
